import OpenAI from "openai";
import { json } from "react-router-dom";

export const askGpt = async (command, data, apiKey) => {
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });

  return await openai.chat.completions.create({
    model: "gpt-4o",
    messages: [
      {
        role: "user",
        content: [
          {
            type: "text",
            text: command,
          },
        ],
      },
      {
        role: "user",
        content: [
          {
            type: "text",
            text: JSON.stringify(data),
          },
        ],
      },
    ],
    response_format: {
      type: "json_object",
    },
    temperature: 0.7,
    max_tokens: 500,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });
};
