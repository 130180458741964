import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";
import AppLogo from "./logo";
import { capitalize } from "../utils/stringUtils";

function Header({ handleMenu }) {
  const { userDetails, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleProfileClick = () => {
    if (userDetails && userDetails.role === "admin") {
      navigate(`/user/${userDetails.id}`);
    } else {
      navigate("/profile");
    }
  };

  return (
    <div>
      <header className="topbar">
        <div className="with-vertical">
          <nav className="navbar navbar-expand-lg p-0">
            <ul className="navbar-nav">
              <li className="nav-item nav-icon-hover-bg rounded-circle ms-n2">
                <button
                  className="nav-link sidebartoggler"
                  id="headerCollapse"
                  onClick={handleMenu}
                >
                  <i className="ti ti-menu-2"></i>
                </button>
              </li>
              <li className="nav-item nav-icon-hover-bg rounded-circle d-none d-lg-flex">
                <button
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="ti ti-search"></i>
                </button>
              </li>
            </ul>

            <div className="d-block d-lg-none py-4">
              <Link to="/" className="text-nowrap logo-img">
                <AppLogo />
              </Link>
            </div>
            <button
              className="navbar-toggler nav-icon-hover-bg rounded-circle p-0 mx-0 border-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti ti-dots fs-7"></i>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="nav-link nav-icon-hover-bg rounded-circle mx-0 ms-n1 d-flex d-lg-none align-items-center justify-content-center"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobilenavbar"
                  aria-controls="offcanvasWithBothOptions"
                >
                  <i className="ti ti-align-justified fs-7"></i>
                </button>
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                  {/* <li className="nav-item nav-icon-hover-bg rounded-circle dropdown">
                    <button
                      className="nav-link"
                      id="drop2"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/images/svgs/icon-flag-en.svg"
                        alt="modernize-img"
                        width="20px"
                        height="20px"
                        className="rounded-circle object-fit-cover round-20"
                      />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="message-body">
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-en.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">English (UK)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-cn.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">中国人 (Chinese)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-fr.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">français (French)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-sa.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">عربي (Arabic)</p>
                        </a>
                      </div>
                    </div>
                  </li> */}

                  {/* <li className="nav-item nav-icon-hover-bg rounded-circle">
                    <button
                      className="nav-link position-relative"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <i className="ti ti-basket"></i>
                      <span className="popup-badge rounded-pill bg-danger text-white fs-2">
                        2
                      </span>
                    </button>
                  </li> */}

                  {/* <li className="nav-item nav-icon-hover-bg rounded-circle dropdown">
                    <button
                      className="nav-link position-relative"
                      id="drop2"
                      aria-expanded="false"
                    >
                      <i className="ti ti-bell-ringing"></i>
                      <div className="notification bg-primary rounded-circle"></div>
                    </button>
                    <div
                      className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="d-flex align-items-center justify-content-between py-3 px-7">
                        <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                        <span className="badge text-bg-primary rounded-4 px-3 py-1 lh-sm">
                          5 new
                        </span>
                      </div>
                      <div className="message-body" data-simplebar>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-2.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Roman Joined the Team!
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Congratulate him
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-3.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              New message
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Salma sent you new message
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-4.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Bianca sent payment
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Check your earnings
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-5.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Jolly completed tasks
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Assign her new tasks
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-6.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              John received payment
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              $230 deducted from account
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-7.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Roman Joined the Team!
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Congratulate him
                            </span>
                          </div>
                        </a>
                      </div>
                      <div className="py-6 px-7 mb-1">
                        <a href="/" className="btn btn-outline-primary w-100">
                          See All Notifications
                        </a>
                      </div>
                    </div>
                  </li> */}

                  <li className="nav-item dropdown">
                    <button
                      className="nav-link pe-0"
                      id="drop1"
                      aria-expanded="false"
                    >
                      <div className="d-flex align-items-center">
                        <div className="user-profile-img">
                          <img
                            src={
                              userDetails?.photoURL ??
                              `https://ui-avatars.com/api/?name=${userDetails?.firstName}+${userDetails?.lastName}&background=random&color=fff`
                            }
                            className="rounded-circle object-fit-cover"
                            width="35"
                            height="35"
                            alt="modernize-img"
                          />
                        </div>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop1"
                    >
                      <div
                        className="profile-dropdown position-relative"
                        data-simplebar
                      >
                        <div className="py-3 px-7 pb-0">
                          <h5 className="mb-0 fs-5 fw-semibold">
                            User Profile
                          </h5>
                        </div>
                        <div
                          className="d-flex align-items-center py-9 mx-7 border-bottom cursor-pointer"
                          onClick={handleProfileClick}
                        >
                          <img
                            src={
                              userDetails?.photoURL ??
                              `https://ui-avatars.com/api/?name=${userDetails?.firstName}+${userDetails?.lastName}&background=random&color=fff`
                            }
                            className="rounded-circle"
                            width="80"
                            height="80"
                            alt="modernize-img object-fit-cover"
                          />
                          <div className="ms-3">
                            <h5 className="mb-1 fs-3">
                              {userDetails?.firstName} {userDetails?.lastName}
                            </h5>
                            <span className="mb-1 d-block">
                              {userDetails?.role
                                ? capitalize(userDetails?.role)
                                : "User"}
                            </span>
                            <p className="mb-0 d-flex align-items-center gap-2">
                              <i className="ti ti-mail fs-4"></i>{" "}
                              {userDetails?.email}
                            </p>
                          </div>
                        </div>
                        <div className="d-grid py-4 px-7 pt-8">
                          <Link
                            onClick={handleLogout}
                            className="btn btn-outline-primary"
                          >
                            Log Out
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {/* <!--  Mobilenavbar --> */}
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            tabIndex="-1"
            id="mobilenavbar"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            <nav className="sidebar-nav scroll-sidebar">
              <div className="offcanvas-header justify-content-between">
                <img
                  src="/assets/images/logos/favicon.ico"
                  alt="modernize-img"
                  className="img-fluid"
                />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body h-n80" data-simplebar="">
                <ul id="sidebarnav">
                  <li className="sidebar-item">
                    <button
                      className="sidebar-link has-arrow"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-apps"></i>
                      </span>
                      <span className="hide-menu">Apps</span>
                    </button>
                    <ul className="collapse first-level my-3">
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-chat.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Chat Application
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              New messages arrived
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-invoice.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Invoice App
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              Get latest invoice
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-mobile.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Contact Application
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              2 Unsaved Contacts
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-message-box.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">Email App</h6>
                            <span className="fs-2 d-block text-muted">
                              Get new emails
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-cart.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              User Profile
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              learn more information
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-date.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Calendar App
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              Get dates
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-lifebuoy.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Contact List Table
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              Add new contact
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="sidebar-item py-2">
                        <Link
                          to="/"
                          className="d-flex align-items-center"
                        >
                          <div className="text-bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/svgs/icon-dd-application.svg"
                              alt="modernize-img"
                              className="img-fluid"
                              width="24"
                              height="24"
                            />
                          </div>
                          <div>
                            <h6 className="mb-1 bg-hover-primary">
                              Notes Application
                            </h6>
                            <span className="fs-2 d-block text-muted">
                              To-do and Daily tasks
                            </span>
                          </div>
                        </Link>
                      </li>
                      <ul className="px-8 mt-7 mb-4">
                        <li className="sidebar-item mb-3">
                          <h5 className="fs-5 fw-semibold">Quick Links</h5>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            Pricing Page
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            Authentication Design
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            Register Now
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            404 Error Page
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            Notes App
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            User Application
                          </Link>
                        </li>
                        <li className="sidebar-item py-2">
                          <Link
                            className="fw-semibold text-dark"
                            to="/"
                          >
                            Account Settings
                          </Link>
                        </li>
                      </ul>
                    </ul>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-message-dots"></i>
                      </span>
                      <span className="hide-menu">Chat</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-calendar"></i>
                      </span>
                      <span className="hide-menu">Calendar</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-mail"></i>
                      </span>
                      <span className="hide-menu">Email</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="app-header with-horizontal">
          <nav className="navbar navbar-expand-xl container-fluid p-0">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item nav-icon-hover-bg rounded-circle d-flex d-xl-none ms-n2">
                <a href="/" className="nav-link sidebartoggler" id="sidebarCollapse">
                  <i className="ti ti-menu-2"></i>
                </a>
              </li>
              <li className="nav-item d-none d-xl-block">
                <Link to="/" className="text-nowrap nav-link">
                  <AppLogo />
                </Link>
              </li>
              <li className="nav-item nav-icon-hover-bg rounded-circle d-none d-xl-flex">
                <button
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="ti ti-search"></i>
                </button>
              </li>
            </ul>
            <button
              className="navbar-toggler nav-icon-hover-bg rounded-circle p-0 mx-0 border-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="p-2">
                <i className="ti ti-dots fs-7"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <div className="d-flex align-items-center justify-content-between px-0 px-xl-8">
                <button
                  className="nav-link round-40 p-1 ps-0 d-flex d-xl-none align-items-center justify-content-center"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#mobilenavbar"
                  aria-controls="offcanvasWithBothOptions"
                >
                  <i className="ti ti-align-justified fs-7"></i>
                </button>
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                  <li className="nav-item nav-icon-hover-bg rounded-circle dropdown">
                    <a href="/" className="nav-link" id="drop2" aria-expanded="false">
                      <img
                        src="/assets/images/svgs/icon-flag-en.svg"
                        alt="modernize-img"
                        width="20px"
                        height="20px"
                        className="rounded-circle object-fit-cover round-20"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="message-body">
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-en.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">English (UK)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-cn.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">中国人 (Chinese)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-fr.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">français (French)</p>
                        </a>
                        <a href="/" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                          <div className="position-relative">
                            <img
                              src="/assets/images/svgs/icon-flag-sa.svg"
                              alt="modernize-img"
                              width="20px"
                              height="20px"
                              className="rounded-circle object-fit-cover round-20"
                            />
                          </div>
                          <p className="mb-0 fs-3">عربي (Arabic)</p>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item nav-icon-hover-bg rounded-circle">
                    <button
                      className="nav-link position-relative"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <i className="ti ti-basket"></i>
                      <span className="popup-badge rounded-pill bg-danger text-white fs-2">
                        2
                      </span>
                    </button>
                  </li>

                  <li className="nav-item nav-icon-hover-bg rounded-circle dropdown">
                    <button
                      className="nav-link position-relative"
                      id="drop2"
                      aria-expanded="false"
                    >
                      <i className="ti ti-bell-ringing"></i>
                      <div className="notification bg-primary rounded-circle"></div>
                    </button>
                    <div
                      className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="d-flex align-items-center justify-content-between py-3 px-7">
                        <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                        <span className="badge text-bg-primary rounded-4 px-3 py-1 lh-sm">
                          5 new
                        </span>
                      </div>
                      <div className="message-body" data-simplebar>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-2.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Roman Joined the Team!
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Congratulate him
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-3.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              New message
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Salma sent you new message
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-4.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Bianca sent payment
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Check your earnings
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-5.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Jolly completed tasks
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Assign her new tasks
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-6.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              John received payment
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              $230 deducted from account
                            </span>
                          </div>
                        </a>
                        <a href="/" className="py-6 px-7 d-flex align-items-center dropdown-item">
                          <span className="me-3">
                            <img
                              src="/assets/images/profile/user-7.jpg"
                              alt="user"
                              className="rounded-circle"
                              width="48"
                              height="48"
                            />
                          </span>
                          <div className="w-100">
                            <h6 className="mb-1 fw-semibold lh-base">
                              Roman Joined the Team!
                            </h6>
                            <span className="fs-2 d-block text-body-secondary">
                              Congratulate him
                            </span>
                          </div>
                        </a>
                      </div>
                      <div className="py-6 px-7 mb-1">
                        <a href="/" className="btn btn-outline-primary w-100">
                          See All Notifications
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <button
                      className="nav-link pe-0"
                      id="drop1"
                      aria-expanded="false"
                    >
                      <div className="d-flex align-items-center">
                        <div className="user-profile-img">
                          <img
                            src="/assets/images/profile/user-1.jpg"
                            className="rounded-circle"
                            width="35"
                            height="35"
                            alt="modernize-img"
                          />
                        </div>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop1"
                    >
                      <div
                        className="profile-dropdown position-relative"
                        data-simplebar
                      >
                        <div className="py-3 px-7 pb-0">
                          <h5 className="mb-0 fs-5 fw-semibold">
                            User Profile
                          </h5>
                        </div>
                        <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                          <img
                            src="/assets/images/profile/user-1.jpg"
                            className="rounded-circle"
                            width="80"
                            height="80"
                            alt="modernize-img"
                          />
                          <div className="ms-3">
                            <h5 className="mb-1 fs-3">Mathew Anderson</h5>
                            <span className="mb-1 d-block">Designer</span>
                            <p className="mb-0 d-flex align-items-center gap-2">
                              <i className="ti ti-mail fs-4"></i>{" "}
                              info@modernize.com
                            </p>
                          </div>
                        </div>
                        <div className="message-body">
                          <Link
                            to="/"
                            className="py-8 px-7 mt-8 d-flex align-items-center"
                          >
                            <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                              <img
                                src="/assets/images/svgs/icon-account.svg"
                                alt="modernize-img"
                                width="24"
                                height="24"
                              />
                            </span>
                            <div className="w-100 ps-3">
                              <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                My Profile
                              </h6>
                              <span className="fs-2 d-block text-body-secondary">
                                Account Settings
                              </span>
                            </div>
                          </Link>
                          <Link
                            to="/"
                            className="py-8 px-7 d-flex align-items-center"
                          >
                            <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                              <img
                                src="/assets/images/svgs/icon-inbox.svg"
                                alt="modernize-img"
                                width="24"
                                height="24"
                              />
                            </span>
                            <div className="w-100 ps-3">
                              <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                My Inbox
                              </h6>
                              <span className="fs-2 d-block text-body-secondary">
                                Messages & Emails
                              </span>
                            </div>
                          </Link>
                          <Link
                            to="/"
                            className="py-8 px-7 d-flex align-items-center"
                          >
                            <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                              <img
                                src="/assets/images/svgs/icon-tasks.svg"
                                alt="modernize-img"
                                width="24"
                                height="24"
                              />
                            </span>
                            <div className="w-100 ps-3">
                              <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                My Task
                              </h6>
                              <span className="fs-2 d-block text-body-secondary">
                                To-do and Daily Tasks
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="d-grid py-4 px-7 pt-8">
                          <div className="upgrade-plan bg-primary-subtle position-relative overflow-hidden rounded-4 p-4 mb-9">
                            <div className="row">
                              <div className="col-6">
                                <h5 className="fs-4 mb-3 fw-semibold">
                                  Unlimited Access
                                </h5>
                                <a href="/" className="btn btn-primary">
                                  Upgrade
                                </a>
                              </div>
                              <div className="col-6">
                                <div className="m-n4 unlimited-img">
                                  <img
                                    src="/assets/images/backgrounds/unlimited-bg.png"
                                    alt="modernize-img"
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link
                            to="/"
                            className="btn btn-outline-primary"
                          >
                            Log Out
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="alert alert-warning show w-100">
            <strong>Warning!</strong> Before you can add events you need to
            verify your email address.
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
