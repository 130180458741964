import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Announcement from "../../../components/announcement";
import { useAuth } from "../../../stores/AuthContext";
import "./edit-product.css";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { updateProduct } from "../../../services/firebase_service";
import Select from "react-select";
import { askGpt } from "../../../services/gpt_service";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

function EditProduct() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [selectionOptions, setSelectionOptions] = useState([]);
  const [gptLoading, setGptLoading] = useState(false);

  const { products, categories, user, users, settings, setProducts } =
    useAuth();
  const navigate = useNavigate();

  // Form Field Vars
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [productLink, setProductLink] = useState("");
  const [productPhoneNumber, setProductPhoneNumber] = useState("");
  const [productAllowWhatsApp, setProductAllowWhatsApp] = useState(false);
  const [productGptReviewed, setProductGptReviewed] = useState(false);
  const [productGptReason, setProductGptReason] = useState("");
  const [productStatus, setProductStatus] = useState("");
  const [productEditedOn, setProductEditedOn] = useState("");
  const [productSellerId, setProductSellerId] = useState("");
  const [productFeatured, setProductFeatured] = useState(false);
  const [productDate, setProductDate] = useState("");

  const handleImageUpload = (e) => {
    const totalFiles = e.target.files.length + productImages.length;
    if (totalFiles <= 5) {
      const files = Array.from(e.target.files);
      setProductImages((prevImages) => [
        ...prevImages,
        ...files.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      toast.error("You can only upload a maximum of 5 images per product.");
    }
  };

  const handleImageRemove = (index) => {
    setProductImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (products && products.length > 0) {
      const product = products.find((product) => product.id === id);

      if (product) {
        setProduct(product);
        setProductTitle(product.itemName);
        setProductDescription(product.description);
        setProductPrice(
          parseInt(product.price).toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
        setProductCategory(product.category);
        setProductLocation(product.location);
        setProductImages(product.images);
        setProductLink(product.link);
        setProductPhoneNumber(product.phoneNumber.number);
        setProductAllowWhatsApp(product.allowWhatsApp);
        setProductGptReviewed(product.gptReviewed);
        setProductGptReason(product.gptReason);
        setProductStatus(product.status);
        setProductEditedOn(product.editedOn);
        setProductSellerId(product.sellerId);
        setProductFeatured(product.featured);
        setProductDate(
          moment(product.timestamp.toDate()).format("YYYY-MM-DDTHH:mm")
        );
      }
    }
    setLoading(false);
  }, [products]);

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        // Extract address components
        const addressComponents = results[0].address_components;

        // Find suburb (locality or sublocality)
        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        // Find province (administrative_area_level_1)
        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        // You can now set these values in your state if needed
        if (suburb && province) {
          setProductLocation(`${suburb}, ${province}`);
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const uploadImages = async (images) => {
    const uploadPromises = images.map(async (image) => {
      if (typeof image === "string" && image.startsWith("http")) {
        return image;
      } else {
        let file = image;
        if (image.startsWith("blob:")) {
          const response = await fetch(image);
          const blob = await response.blob();
          file = new File([blob], `image_${new Date().getTime()}.jpg`, {
            type: "image/jpeg",
          });
        }

        const storageRef = ref(storage, `productImages/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              console.error("Upload error:", error);
              toast.error("Failed to upload the image.");
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      }
    });

    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadedImages = await uploadImages(productImages);

    const updatedProduct = {
      id: id,
      itemName: productTitle,
      description: productDescription,
      price: parseInt(productPrice.replace(",", "")).toString(),
      category: productCategory,
      location: productLocation,
      images: uploadedImages,
      link: productLink,
      phoneNumber: {
        number: productPhoneNumber,
        allowWhatsApp: productAllowWhatsApp,
      },
      gptReviewed: productGptReviewed,
      gptReason: productGptReason,
      status: productStatus,
      editedOn: productEditedOn,
      sellerId: productSellerId,
      featured: productFeatured,
      timestamp: Timestamp.fromDate(new Date(productDate)),
    };

    // Save updatedProduct to your database
    try {
      // console.log("Updated product:", updatedProduct);
      await updateProduct(id, updatedProduct, user);
      setProducts((prevProducts) => {
        return prevProducts.map((product) => {
          if (product.id === id) {
            return updatedProduct;
          }
          return product;
        });
      });
      setLoading(false);
      navigate("/marketplace");
      toast.success("Product updated successfully");
    } catch (error) {
      setLoading(false);
      console.error("Error updating product:", error);
    }
  };

  const getDefaultValue = () => {
    if (users && users.length > 0) {
      const seller = users.find((user) => user.id === productSellerId);
      if (seller) {
        return {
          value: seller.id,
          label: `${seller.firstName} ${seller.lastName}`,
        };
      }
    }
    return {
      value: "system",
      label: "Funduro Team",
    };
  };

  useEffect(() => {
    setSelectionOptions([{ value: "system", label: "Funduro Team" }]);
    if (users && users.length > 0) {
      users.forEach((user) => {
        setSelectionOptions((prevOptions) => [
          ...prevOptions,
          {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          },
        ]);
      });
    }
  }, [users]);

  const handleGptImprove = async (e) => {
    setGptLoading(true);
    const productDetails = {
      name: productTitle,
      description: productDescription,
    };

    // Call GPT API to improve product details
    try {
      const improvedDetails = await askGpt(
        "Improve the the below product details and only respond with a json object like: {productName: name,productDescription: desc}",
        productDetails,
        settings.find((s) => s.id === "gptSettings").accessToken
      );
      setGptLoading(false);
      const gptMessage = JSON.parse(improvedDetails.choices[0].message.content);
      if (gptMessage.productName) {
        setProductTitle(gptMessage.productName);
      }
      if (gptMessage.productDescription) {
        setProductDescription(gptMessage.productDescription);
      }
    } catch (error) {
      console.error("Error improving product details:", error);
      setGptLoading(false);
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-md-9">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Product</h4>
                  <p className="mb-0">Add your product details below</p>
                </div>
                <div className="right">
                  <Link to="/marketplace" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Marketplace
                  </Link>
                </div>
              </div>
            </div>
            {product && (
              <form id="editProductForm" onSubmit={handleSubmit}>
                <div className="card-body pt-0 row">
                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label
                        htmlFor="productSellerId"
                        className="d-flex form-label"
                      >
                        Seller <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="productSellerId"
                        id="productSellerId"
                        defaultValue={getDefaultValue}
                        onChange={(e) => setProductSellerId(e.value)}
                        options={selectionOptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            background: "transparent",
                            border: "1px solid #333f55",
                            color: "#fff",
                            borderRadius: "7px",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#fff",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#7c8fac",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#fff",
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label htmlFor="productTitle" className="form-label">
                        Product Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productTitle"
                        name="productTitle"
                        value={productTitle}
                        onChange={(e) => setProductTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label htmlFor="productCategory" className="form-label">
                        Category <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="productCategory"
                        id="productCategory"
                        value={productCategory}
                        onChange={(e) => setProductCategory(e.target.value)}
                      >
                        {categories.map((category) => {
                          return (
                            <option key={category.name} value={category.name}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label htmlFor="productLocation" className="form-label">
                        Event Location <span className="text-danger">*</span>
                      </label>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyD_Lsht21cw3UEhTUP9cPW0R9kylZIqCmM"
                        selectProps={{
                          className:
                            "locationAutoComplete" +
                            (locationError ? " is-invalid" : ""),
                          onChange: handlePlaceSelected,
                          placeholder: "Search for a location",
                          onFocus: (e) => {
                            e.target.value = "";
                            setLocationError(null);
                          },
                          styles: {
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#7c8fac",
                            }),
                            control: (provided) => ({
                              ...provided,
                              background: "transparent",
                              border: "1px solid #333f55",
                              borderRadius: "5px",
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: "#fff",
                              cursor: "text",
                            }),
                            option: (provided) => ({
                              ...provided,
                              color: "#fff",
                              background: "#0F1820",
                              cursor: "pointer",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              background: "#0F1820",
                            }),
                            loadingIndicator: (provided) => ({
                              ...provided,
                              color: "#fff",
                            }),
                            loadingMessage: (provided) => ({
                              ...provided,
                              color: "#fff",
                            }),
                            noOptionsMessage: (provided) => ({
                              ...provided,
                              color: "#fff",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "#fff",
                            }),
                          },
                        }}
                        apiOptions={{
                          region: "za",
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["za"],
                          },
                        }}
                        style={{ background: "#ff0000" }}
                      />
                      {locationError ? (
                        <div className="invalid-feedback">{locationError}</div>
                      ) : null}
                      {productLocation ? (
                        <small className="text-muted">{productLocation}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label
                        htmlFor="productPhoneNumber"
                        className="form-label"
                      >
                        Contact Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productPhoneNumber"
                        name="productPhoneNumber"
                        value={productPhoneNumber}
                        onChange={(e) => setProductPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="mb-3">
                      <label htmlFor="productPrice" className="form-label">
                        Product Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productPrice"
                        name="productPrice"
                        value={productPrice}
                        onChange={(e) => setProductPrice(e.target.value)}
                        onBlur={(e) => {
                          const enteredPrice = e.target.value.replace(",", "");
                          setProductPrice(
                            parseInt(enteredPrice).toLocaleString("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label htmlFor="productLink" className="form-label">
                        Product Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productLink"
                        name="productLink"
                        defaultValue={productLink}
                        onChange={(e) => setProductLink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label
                        htmlFor="productDescription"
                        className="form-label"
                      >
                        Product Description
                      </label>
                      <textarea
                        className="form-control"
                        id="productDescription"
                        rows="15"
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="card sticky">
            <div className="card-body">
              <button
                type="button"
                className="btn btn-light w-100 mb-2"
                onClick={handleGptImprove}
                disabled={gptLoading}
              >
                {gptLoading ? (
                  <i className="ti ti-loader spin me-1"></i>
                ) : (
                  <i className="ti ti-robot me-1"></i>
                )}
                Improve with AI
              </button>
              <button
                type="submit"
                className="btn btn-primary w-100 mb-2"
                form="editProductForm"
                disabled={loading}
              >
                {loading ? (
                  <i className="ti ti-loader spin me-1"></i>
                ) : (
                  <i className="ti ti-device-floppy me-1"></i>
                )}
                Save Changes
              </button>

              <div className="col-md-12 mt-4">
                <label for="productImages" class="form-label">
                  Date Posted
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="productDate"
                  name="productDate"
                  value={productDate}
                  onChange={(e) => setProductDate(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mt-4">
                <label for="productImages" class="form-label">
                  Product Images
                </label>
                <Dropzone
                  accept={"image/*"}
                  maxSize={3072000}
                  onDrop={(acceptedFiles) =>
                    handleImageUpload({ target: { files: acceptedFiles } })
                  }
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => {
                    const addtionalClass = isDragAccept
                      ? "accept"
                      : isDragReject
                      ? "reject"
                      : "";

                    return (
                      <div
                        {...getRootProps({
                          className: `dropzone mb-4 ${addtionalClass} ps-4 pe-4 text-center`,
                        })}
                      >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p className="p-0 m-0">Drop files here...</p>
                        ) : (
                          <p className="p-0 m-0">
                            Drop files here to upload, or click to select files
                          </p>
                        )}
                      </div>
                    );
                  }}
                </Dropzone>

                <div className="image-gallery mb-4">
                  {productImages.map((image, index) => (
                    <div key={index} className="image-item">
                      <img
                        src={
                          typeof image === "string"
                            ? image
                            : URL.createObjectURL(image)
                        }
                        alt={`Product Image ${index + 1}`}
                        className="img-thumbnail"
                      />
                      <button
                        type="button"
                        className="btn close-btn"
                        onClick={() => handleImageRemove(index)}
                      >
                        <i className="ti ti-x"></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
