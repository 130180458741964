import React, { useState, useCallback, useEffect } from "react";
import Announcement from "../../../components/announcement";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { useAuth } from "../../../stores/AuthContext";
import { storage } from "../../../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateRidingSpot } from "../../../services/firebase_service";

function EditRidingSpot() {
  const { id } = useParams();
  const { userDetails, ridingSpots, setRidingSpots } = useAuth();

  const [loading, setLoading] = useState(false);
  const [RidingSpot, setRidingSpot] = useState(null);

  const [ridingSpotJson, setRidingSpotJson] = useState("");

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [location, setLocation] = useState({});
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [distance, setDistance] = useState("");
  const [ridingTypes, setRidingTypes] = useState([]);
  const [bikeType, setBikeType] = useState("");
  const [riderLevel, setRiderLevel] = useState("");
  const [price, setPrice] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [published, setPublished] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const data = ridingSpots?.find((spot) => spot.id === id);
    if (data && !isInitialized) {
      setRidingSpot(data);
      setRidingSpotJson(
        JSON.stringify(
          {
            name: data.name,
            location: data.location,
            imageUrl: data.imageUrl,
            description: data.description,
            distance: data.distance,
            ridingTypes: data.ridingTypes,
            bikeType: data.bikeType,
            riderLevel: data.riderLevel,
            price: data.price,
            contactNumber: data.contactNumber,
            contactEmail: data.contactEmail,
          },
          null,
          2
        )
      );

      if (
        data.location !== "" &&
        data.location["city"] !== "" &&
        data.location["city"] !== null &&
        data.location["province"] !== "" &&
        data.location["province"] !== null
      ) {
        setCity(data.location["city"]);
        setProvince(data.location["province"]);
      }

      setName(data.name || "");
      setLocation(`${data.location["city"]}, ${data.location["city"]}` || "");
      setRiderLevel(data.riderLevel || "");
      setBikeType(data.bikeType || "Bikes & Quads");
      setPrice(data.price || "");
      setDistance(data.distance || "");
      setDescription(data.description || "");
      setPreview(data.imageUrl || "");
      setPublished(data.published || false);
      setContactEmail(data.contactEmail || "");
      setContactNumber(data.contactNumber || "");

      setIsInitialized(true);
    }
  }, [id, ridingSpots, isInitialized]);

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    const storageRef = ref(storage, `ridingSpotImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          Swal.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadedImageUrl(downloadURL);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    console.log("Submitting form");
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl;
      if (selectedFile) {
        imageUrl = await uploadImage();
      } else {
        imageUrl = preview;
      }
      const data = {
        id: id,
        name: name,
        location: {
          city: city,
          province: province,
          street: null,
          place: name,
          country: "South Africa",
        },
        imageUrl: imageUrl,
        description: description,
        distance: distance,
        ridingTypes: ridingTypes,
        bikeType: bikeType,
        riderLevel: riderLevel,
        price: price,
        contactNumber: contactNumber.replace(/\s+/g, "").trim(),
        contactEmail: contactEmail,
        published: published,
      };
      await updateRidingSpot(id, data);
      const updatedRidingSpots = ridingSpots.filter((spot) => spot.id !== id);
      updatedRidingSpots.push(data);
      // Update the RidingSpots in the AuthContext
      setRidingSpots(updatedRidingSpots);
      setLoading(false);
      toast.success("Riding Spot saved successfully!");
      navigate("/riding-spots");
    } catch (error) {
      setLoading(false);
      toast.error(`Error saving Riding Spot: ${error}`);
      console.error("Error saving RidingSpot:", error);
    }
  };
  const processJson = () => {
    console.log("Processing JSON");

    //   // check of the JSON is valid
    try {
      const jsonData = JSON.parse(ridingSpotJson);

      console.log(jsonData);
      //     setRidingSpotTitle(jsonData.RidingSpotTitle);
      //     setRidingSpotStartDate(jsonData.StartDate);
      //     setRidingSpotEndDate(jsonData.EndDate);
      //     setRidingSpotLocation(jsonData.RidingSpotLocation);
      //     setRiderLevel(jsonData.RiderLevel);
      //     setRidingSpotDistance(jsonData.Distance);
      //     setBikeType(jsonData.BikeType);
      //     setRidingSpotPrice(jsonData.Price);
      //     setRidingSpotLink(jsonData.RidingSpotLink);
      //     if (jsonData.ImageUrl) {
      //       const imageUrl = jsonData.ImageUrl;
      //       fetch(imageUrl)
      //         .then((response) => response.blob())
      //         .then((blob) => {
      //           const file = new File([blob], "tempImage.jpg", { type: blob.type });
      //           handleDrop([file]);
      //         })
      //         .catch((error) => console.error("Error downloading image:", error));
      //     }
    } catch (error) {
      toast.error(`Error!, Invalid JSON. ${error}`);
      return;
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Riding Spot</h4>
                  <p className="mb-0">Add your RidingSpot details below</p>
                </div>
                <div className="right">
                  <Link to="/riding-spots" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Riding Spots
                  </Link>
                </div>
              </div>
            </div>
            {RidingSpot && (
              <form id="editRidingSpotForm" onSubmit={handleSubmit}>
                <div className="card-body pt-0 row">
                  <div className="col-xl-8">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactEmail"
                            name="contactEmail"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactNumber"
                            name="contactNumber"
                            value={contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="location" className="form-label">
                            Event Location{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control me-2"
                              id="location"
                              name="location"
                              value={location}
                              onChange={(e) => {
                                setLocation("");
                                setLocation(e.target.value);
                                if (province !== "") {
                                  setLocation(e.target.value + ", " + province);
                                }
                              }}
                              required
                            />
                            <select
                              className="form-select"
                              name="province"
                              id="province"
                              value={province}
                              onChange={(e) => {
                                setLocation("");
                                if (location !== "" && e.target.value !== "") {
                                  setProvince(e.target.value);
                                  setLocation(location + ", " + e.target.value);
                                }
                              }}
                            >
                              <option value="" selected disabled>
                                Select a Province
                              </option>
                              <option value="Eastern Cape">Eastern Cape</option>
                              <option value="Free State">Free State</option>
                              <option value="Gauteng">Gauteng</option>
                              <option value="KwaZulu-Natal">
                                KwaZulu-Natal
                              </option>
                              <option value="Limpopo">Limpopo</option>
                              <option value="Mpumalanga">Mpumalanga</option>
                              <option value="North West">North West</option>
                              <option value="Northern Cape">
                                Northern Cape
                              </option>
                              <option value="Western Cape">Western Cape</option>
                            </select>
                          </div>
                          {location ? (
                            <small className="text-muted">{location}</small>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="bikeType" className="form-label">
                            Bike Type <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="bikeType"
                            id="bikeType"
                            value={bikeType}
                            onChange={(e) => setBikeType(e.target.value)}
                          >
                            <option value="Bikes & Quads">Bikes & Quads</option>
                            <option value="Bikes Only">Bikes Only</option>
                            <option value="Quads Only">Quads Only</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="riderLevel" className="form-label">
                            Rider Level
                          </label>
                          <select
                            className="form-select"
                            name="riderLevel"
                            id="riderLevel"
                            value={riderLevel}
                            onChange={(e) => setRiderLevel(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="All">All</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="distance" className="form-label">
                            Distance
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="distance"
                            name="distance"
                            value={distance}
                            onChange={(e) => setDistance(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="price" className="form-label">
                            Entry Fee
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="price"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3">
                          <label htmlFor="description" className="form-label">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="description"
                            rows="15"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="mb-3">
                      <label className="form-label">
                        Image <span className="text-danger">*</span>
                      </label>
                      <div
                        {...getRootProps({
                          className:
                            "dropzone border rounded-3 p-3 text-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        {preview ? (
                          <div>
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "150px",
                                marginBottom: "10px",
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={removeFile}
                            >
                              Remove File
                            </button>
                          </div>
                        ) : (
                          <p className="mb-0">
                            Drag an image here, or click to upload
                          </p>
                        )}
                      </div>
                    </div>

                    {userDetails?.role === "admin" ? (
                      <div className="mb-3 d-flex justify-content-end flex-column">
                        <label htmlFor="ridingSpotJson" className="form-label">
                          Import JSON
                        </label>
                        <textarea
                          className="form-control"
                          id="ridingSpotJson"
                          rows="10"
                          value={ridingSpotJson}
                          onChange={(e) => setRidingSpotJson(e.target.value)}
                        />
                        <button
                          className="btn btn-grey mt-3"
                          type="button"
                          onClick={processJson}
                        >
                          Parse JSON
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <div className="form-check form-switch py-2 me-4">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      id="published"
                      defaultChecked={published}
                      onChange={(e) => setPublished(!published)}
                    />
                    <label className="form-check-label" htmlFor="published">
                      Publish
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary d-flex">
                    {uploading || loading ? (
                      <div className="spin me-2">
                        <i className="ti ti-loader"></i>
                      </div>
                    ) : null}
                    Save
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRidingSpot;
