import React, { useState, useCallback } from "react";
import Announcement from "../../../components/announcement";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { useAuth } from "../../../stores/AuthContext";
import { storage } from "../../../firebase";
import { addRidingSpot } from "../../../services/firebase_service";
import { Link, useNavigate } from "react-router-dom";

function NewRidingSpot() {
  const navigate = useNavigate();
  const { userDetails, ridingSpots } = useAuth();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [distance, setDistance] = useState("");
  const [ridingTypes, setRidingTypes] = useState([]);
  const [bikeType, setBikeType] = useState("");
  const [riderLevel, setRiderLevel] = useState("");
  const [price, setPrice] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [published, setPublished] = useState(false);

  const [ridingSpotJson, setRidingSpotJson] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      Swal.fire("Error!", "Please select an image before saving.", "error");
      return null;
    }

    const storageRef = ref(storage, `ridingSpotImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          Swal.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadedImageUrl(downloadURL);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const imageUrl = await uploadImage();

      if (imageUrl === "" || imageUrl === null) {
        toast.error("Please upload an image before saving.");
        return;
      } else {
        const data = {
          name: name,
          location: {
            city: city,
            province: province,
            country: "South Africa",
            address: address,
            place: name,
            street: street ?? "",
          },
          imageUrl: imageUrl,
          description: description,
          distance: distance,
          ridingTypes: ridingTypes,
          bikeType: bikeType,
          riderLevel: riderLevel,
          price: price,
          contactNumber: contactNumber,
          contactEmail: contactEmail,
        };

        await addRidingSpot(data);

        setName("");
        setLocation({});
        setCity("");
        setProvince("");
        setImageUrl("");
        setDescription("");
        setDistance("");
        setRidingTypes([]);
        setBikeType("");
        setRiderLevel("");
        setPrice("");
        setContactNumber("");
        setContactEmail("");
        setPublished(false);

        ridingSpots.push(data);

        setLoading(false);

        navigate("/riding-spots");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error adding Riding Spot: ${error}`);
      console.error("Error adding Riding Spot:", error);
    }
  };

  const processJson = () => {
    // check of the JSON is valid
    try {
      const jsonData = JSON.parse(ridingSpotJson);
      console.log(jsonData);

      setName(jsonData.Name);
      setLocation(jsonData.Location);
      setCity(jsonData.City);
      setProvince(jsonData.Province);
      setDescription(jsonData.Description);
      setDistance(jsonData.Distance);
      setRidingTypes(jsonData.RidingTypes);
      setBikeType(jsonData.BikeType);
      setRiderLevel(jsonData.RiderLevel);
      setPrice(jsonData.Price);
      setContactNumber(jsonData.ContactNumber);
      setContactEmail(jsonData.ContactEmail);

      if (jsonData.ImageUrl) {
        const imageUrl = jsonData.ImageUrl;
        fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "tempImage.jpg", { type: blob.type });
            handleDrop([file]);
          })
          .catch((error) => console.error("Error downloading image:", error));
      }
    } catch (error) {
      toast.error(`Error!, Invalid JSON. ${error}`);
      return;
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">New Event</h4>
                  <p className="mb-0">Add your event details below</p>
                </div>
                <div className="right">
                  <Link to="/events" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Events
                  </Link>
                </div>
              </div>
            </div>
            <form id="newEventForm" onSubmit={handleSubmit}>
              <div className="card-body pt-0 row">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactEmail"
                          name="contactEmail"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactNumber"
                          name="contactNumber"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="location" className="form-label">
                          Event Location <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control me-2"
                            id="location"
                            name="location"
                            value={location}
                            onChange={(e) => {
                              setLocation("");
                              setLocation(e.target.value);
                              if (province !== "") {
                                setLocation(e.target.value + ", " + province);
                              }
                            }}
                            required
                          />
                          <select
                            className="form-select"
                            name="province"
                            id="province"
                            value={province}
                            onChange={(e) => {
                              setLocation("");
                              if (location !== "" && e.target.value !== "") {
                                setProvince(e.target.value);
                                setLocation(location + ", " + e.target.value);
                              }
                            }}
                          >
                            <option value="" selected disabled>
                              Select a Province
                            </option>
                            <option value="Eastern Cape">Eastern Cape</option>
                            <option value="Free State">Free State</option>
                            <option value="Gauteng">Gauteng</option>
                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                            <option value="Limpopo">Limpopo</option>
                            <option value="Mpumalanga">Mpumalanga</option>
                            <option value="North West">North West</option>
                            <option value="Northern Cape">Northern Cape</option>
                            <option value="Western Cape">Western Cape</option>
                          </select>
                        </div>
                        {location ? (
                          <small className="text-muted">{location}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="bikeType" className="form-label">
                          Bike Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="bikeType"
                          id="bikeType"
                          value={bikeType}
                          onChange={(e) => setBikeType(e.target.value)}
                        >
                          <option value="Bikes & Quads">Bikes & Quads</option>
                          <option value="Bikes Only">Bikes Only</option>
                          <option value="Quads Only">Quads Only</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="riderLevel" className="form-label">
                          Rider Level
                        </label>
                        <select
                          className="form-select"
                          name="riderLevel"
                          id="riderLevel"
                          value={riderLevel}
                          onChange={(e) => setRiderLevel(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="All">All</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Expert">Expert</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="distance" className="form-label">
                          Distance
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="distance"
                          name="distance"
                          value={distance}
                          onChange={(e) => setDistance(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="price" className="form-label">
                          Entry Fee
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="price"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <Editor
                          apiKey="7islfbqk6jv9f0kuf9w3b60ce00uv0jagjbof6c9a71v8bw6"
                          value={description}
                          onEditorChange={(content) =>
                            setDescription(content)
                          }
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | code |" +
                              "bold italic | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist | " +
                              "removeformat",
                            statusbar: false,
                            content_style:
                              "body { background-color: #0F1820; color: #fff; font-family: 'Poppins', sans-serif; }",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Event Image <span className="text-danger">*</span>
                    </label>
                    <div
                      {...getRootProps({
                        className: "dropzone border rounded-3 p-3 text-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      {preview ? (
                        <div>
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeFile}
                          >
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <p className="mb-0">
                          Drag an image here, or click to upload
                        </p>
                      )}
                    </div>
                  </div>

                  {userDetails?.role === "admin" ? (
                    <div className="mb-3 d-flex justify-content-end flex-column">
                      <label htmlFor="ridingSpotJson" className="form-label">
                        Import JSON
                      </label>
                      <textarea
                        className="form-control"
                        id="ridingSpotJson"
                        rows="10"
                        value={ridingSpotJson}
                        onChange={(e) => setRidingSpotJson(e.target.value)}
                      />
                      <button
                        className="btn btn-grey mt-3"
                        type="button"
                        onClick={processJson}
                      >
                        Parse JSON
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="published"
                    defaultChecked={published}
                    onChange={(e) => setPublished(!published)}
                  />
                  <label className="form-check-label" htmlFor="published">
                    Publish
                  </label>
                </div>
                <button type="submit" className="btn btn-primary d-flex">
                  {uploading || loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRidingSpot;
