import { db } from "../firebase";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

export const addEvent = (event) => {
  return addDoc(collection(db, "events"), event);
};

export const addRidingSpot = (ridingSpot) => {
  return addDoc(collection(db, "riding_spots"), ridingSpot);
}

export const updateSettings = (id, settings) => {
  return setDoc(doc(db, "settings", id), settings);
}

export const updateEvent = (eventId, event) => {
  return setDoc(doc(db, "events", eventId), event);
};

export const updateRidingSpot = (id, ridingSpot) => {
  return setDoc(doc(db, "riding_spots", id), ridingSpot);
};

export const updateUser = async (userId, userData) => {
  try {
    const userRef = doc(db, "users", userId);
    const updateData = {
      ...userData,
      updatedAt: serverTimestamp(),
    };
    delete updateData.uid; // Remove uid from update data
    await updateDoc(userRef, updateData);
    return true;
  } catch (error) {
    console.error("Error updating user:", error);
    throw new Error("Permission denied: Unable to update user data");
  }
};

export const updateUserLoginStatus = async (userId, status) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      status: status,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error("Error updating user login status:", error);
    throw new Error("Permission denied: Unable to update user status");
  }
};

export const updateProduct = async (productId, productData, user) => {
  const productRef = doc(db, "products", productId);
  const updateData = {
    ...productData,
    updatedAt: serverTimestamp(),
    updatedBy: user.uid,
  };
  return await updateDoc(productRef, updateData);
};

export const addProduct = async (productData, user) => {
  const productRef = await addDoc(collection(db, "products"), {});
  const newProduct = {
    ...productData,
    id: productRef.id,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    status: "approved",
    createdBy: user.uid,
    updatedBy: user.uid,
  };
  return await setDoc(productRef, newProduct);
}

export const deleteProduct = async (productId) => {
  const productRef = doc(db, "products", productId);
  return await updateDoc(productRef, {
    status: "deleted",
    updatedAt: serverTimestamp(),
  });
};