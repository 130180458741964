import React, { useEffect, useState } from "react";
import Announcement from "../../components/announcement";
import { useAuth } from "../../stores/AuthContext";
import { updateSettings } from "../../services/firebase_service";
import { toast } from "react-toastify";

function Settings() {
  const { settings, setSettings } = useAuth();
  const [loading, setLoading] = useState(true);

  const [appSettings, setAppSettings] = useState({
    allowGuestBrowsing: false,
    googleMapsAPI: "",
    sendgridApiKey: "",
    supportEmail: "",
  });

  const [chatSettings, setChatSettings] = useState({
    blockedWords: [],
  });

  const [gptSettings, setGptSettings] = useState({
    accessToken: "",
    rules: [],
  });

  const [paywallSettings, setPaywallSettings] = useState({
    proFeatures: [],
    revenueCatApiKey: "",
  });

  const [shopSettings, setShopSettings] = useState({
    soldReasons: [],
  });

  useEffect(() => {
    // If `settings` is an array of objects, each with a `type` field
    settings.forEach((element) => {
      switch (element.id) {
        case "appSettings":
          setAppSettings(element);
          break;
        case "chatSettings":
          setChatSettings(element);
          break;
        case "gptSettings":
          setGptSettings(element);
          break;
        case "paywallSettings":
          setPaywallSettings(element);
          break;
        case "shopSettings":
          setShopSettings(element);
          break;
        default:
          break;
      }
    });
    setLoading(false);
  }, [settings]);

  /**
   * 1) APP SETTINGS
   */
  const handleAppChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppSettings((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Example Firestore update or local context update
  const handleAppSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // If storing each settings type in its own doc:
      await updateSettings("appSettings", appSettings);
      setSettings((prev) => {
        return prev.map((setting) =>
          setting.id === "appSettings" ? appSettings : setting
        );
      });

      toast.success("App settings updated successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error updating app settings: ", error);
      toast.error("Error updating app settings: ", error);
      setLoading(false);
    }
  };

  /**
   * 2) CHAT SETTINGS
   */
  const handleChatChange = (e) => {
    const { name, value } = e.target;
    // If `blockedWords` is comma-separated
    if (name === "blockedWords") {
      // Convert comma-separated string to array
      setChatSettings((prev) => ({
        ...prev,
        blockedWords: value.split(",").map((word) => word.trim()),
      }));
    } else {
      setChatSettings((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChatSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await updateSettings("chatSettings", chatSettings);
      toast.success("Chat settings updated successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error updating chat settings: ", error);
      toast.error("Error updating chat settings: ", error);
      setLoading(false);
    }
  };

  /**
   * 3) GPT SETTINGS
   */
  const handleGptChange = (e) => {
    const { name, value } = e.target;
    // If `rules` is comma-separated
    if (name === "rules") {
      setGptSettings((prev) => ({
        ...prev,
        rules: value.split(",").map((rule) => rule.trim()),
      }));
    } else {
      setGptSettings((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleGptSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await updateSettings("gptSettings", gptSettings);

      toast.success("GPT settings updated successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error updating GPT settings: ", error);
      toast.error("Error updating GPT settings: ", error);
      setLoading(false);
    }
  };

  /**
   * 4) PAYWALL SETTINGS
   */
  const handlePaywallChange = (e) => {
    const { name, value } = e.target;
    // If `proFeatures` is comma-separated
    if (name === "proFeatures") {
      setPaywallSettings((prev) => ({
        ...prev,
        proFeatures: value.split(",").map((feature) => feature.trim()),
      }));
    } else {
      setPaywallSettings((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePaywallSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await updateSettings("paywallSettings", paywallSettings);

      toast.success("Paywall settings updated successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error updating paywall settings: ", error);
      toast.error("Error updating paywall settings: ", error);
      setLoading(false);
    }
  };

  /**
   * 5) SHOP SETTINGS
   */
  const handleShopChange = (e) => {
    const { name, value } = e.target;
    // If `soldReasons` is comma-separated
    if (name === "soldReasons") {
      setShopSettings((prev) => ({
        ...prev,
        soldReasons: value.split(",").map((reason) => reason.trim()),
      }));
    } else {
      setShopSettings((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleShopSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await updateSettings("shopSettings", shopSettings);

      toast.success("Shop settings updated successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error updating shop settings: ", error);
      toast.error("Error updating shop settings: ", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Settings</h4>
                  <p className="mb-0">Manage and view all app settings</p>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <ul className="nav nav-pills nav-fill mb-4" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#appSettings"
                    role="tab"
                  >
                    <span>App Settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#chatSettings"
                    role="tab"
                  >
                    <span>Chat Settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#gptSettings"
                    role="tab"
                  >
                    <span>GPT Settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#paywallSettings"
                    role="tab"
                  >
                    <span>Paywall Settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#shopSettings"
                    role="tab"
                  >
                    <span>Shop Settings</span>
                  </a>
                </li>
              </ul>
              {/* Tab Contents */}
              <div className="tab-content border mt-2">
                {/* --------------------------- APP SETTINGS --------------------------- */}
                <div
                  className="tab-pane active p-3"
                  id="appSettings"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h5>App Settings</h5>
                      <p>Configure general application settings here.</p>
                      <form onSubmit={handleAppSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="allowGuestBrowsing">
                            Allow Guest Browsing
                          </label>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="allowGuestBrowsing"
                              name="allowGuestBrowsing"
                              checked={appSettings.allowGuestBrowsing}
                              onChange={handleAppChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowGuestBrowsing"
                            >
                              Enable
                            </label>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="googleMapsAPI">
                            Google Maps API Key
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="googleMapsAPI"
                            name="googleMapsAPI"
                            value={appSettings.googleMapsAPI}
                            onChange={handleAppChange}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="sendgridApiKey">
                            SendGrid API Key
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sendgridApiKey"
                            name="sendgridApiKey"
                            value={appSettings.sendgridApiKey}
                            onChange={handleAppChange}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="supportEmail">Support Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="supportEmail"
                            name="supportEmail"
                            value={appSettings.supportEmail}
                            onChange={handleAppChange}
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary d-flex"
                          disabled={loading}
                        >
                          {loading ? (
                            <div className="spin me-2">
                              <i className="ti ti-loader"></i>
                            </div>
                          ) : null}
                          Save Settings
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* --------------------------- CHAT SETTINGS --------------------------- */}
                <div className="tab-pane p-3" id="chatSettings" role="tabpanel">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Chat Settings</h5>
                      <p>Configure chat related settings here.</p>
                      <form onSubmit={handleChatSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="blockedWords">
                            Additional Blocked Words
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="blockedWords"
                            name="blockedWords"
                            value={chatSettings.blockedWords.join(", ")}
                            onChange={handleChatChange}
                          />
                          <small className="text-muted">
                            Enter comma-separated words (e.g. "spam, rude,
                            foul"). These are words that may not be picked up by
                            an automated system.
                          </small>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Save Chat Settings
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* --------------------------- GPT SETTINGS --------------------------- */}
                <div className="tab-pane p-3" id="gptSettings" role="tabpanel">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>GPT Settings</h5>
                      <p>Configure GPT related settings here.</p>
                      <form onSubmit={handleGptSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="accessToken">Access Token</label>
                          <input
                            type="text"
                            className="form-control"
                            id="accessToken"
                            name="accessToken"
                            value={gptSettings.accessToken}
                            onChange={handleGptChange}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="rules">Rules</label>
                          <input
                            type="text"
                            className="form-control"
                            id="rules"
                            name="rules"
                            value={gptSettings.rules.join(", ")}
                            onChange={handleGptChange}
                          />
                          <small className="text-muted">
                            Enter comma-separated rules
                          </small>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Save GPT Settings
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* --------------------------- PAYWALL SETTINGS --------------------------- */}
                <div
                  className="tab-pane p-3"
                  id="paywallSettings"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Paywall Settings</h5>
                      <p>Configure paywall related settings here.</p>
                      <form onSubmit={handlePaywallSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="proFeatures">Pro Features</label>
                          <input
                            type="text"
                            className="form-control"
                            id="proFeatures"
                            name="proFeatures"
                            value={paywallSettings.proFeatures.join(", ")}
                            onChange={handlePaywallChange}
                          />
                          <small className="text-muted">
                            Enter comma-separated features
                          </small>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="revenueCatApiKey">
                            RevenueCat API Key
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="revenueCatApiKey"
                            name="revenueCatApiKey"
                            value={paywallSettings.revenueCatApiKey}
                            onChange={handlePaywallChange}
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Save Paywall Settings
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/* --------------------------- SHOP SETTINGS --------------------------- */}
                <div className="tab-pane p-3" id="shopSettings" role="tabpanel">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Shop Settings</h5>
                      <p>Configure shop related settings here.</p>
                      <form onSubmit={handleShopSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="soldReasons">Sold Reasons</label>
                          <input
                            type="text"
                            className="form-control"
                            id="soldReasons"
                            name="soldReasons"
                            value={shopSettings.soldReasons.join(", ")}
                            onChange={handleShopChange}
                          />
                          <small className="text-muted">
                            Enter comma-separated reasons (e.g. "Out of stock,
                            Discontinued")
                          </small>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Save Shop Settings
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                {/* -------------------------------------------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
